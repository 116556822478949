import {filter, tap} from 'rxjs/operators';
import {Observable, MonoTypeOperatorFunction} from 'rxjs';

export function filterFalsy<T>(otherCondition = true) {
  return filter<T>(value => otherCondition && !!value);
}

/**
 * Applies a side effect on the first emitted value of an observable sequence
 * and then ignores subsequent values. The provided callback function will only be triggered once
 * upon the first subscription to the observable sequence.
 */
export function tapOnce<T>(callbackFn: (value: T) => void): MonoTypeOperatorFunction<T> {
  return (source$: Observable<T>) => {
    let first = true;

    return source$.pipe(
      tap(value => {
        if (first) {
          callbackFn(value);
          first = false;
        }
      })
    );
  };
}
