import {CloudName, CloudType, Environment} from '@q9elements/ui-core';

export const environment: Environment = {
    version: require('../../package.json').version,
    PX_KEY: 'AP-3EN4L67VSTXG-2-2',
    API_ENDPOINT: 'https://preprod-api.q9elements.com/api/v1', 
    API_STATIC_FILE_UPLOAD: 'https://preprod-upload.q9elements.com:1443/api',
    APPNAME: 'HD-APP',
    CLOUD_NAME: CloudName.ELEMENTS,
    CLOUD_TYPE: CloudType.PUBLIC,
    DATA_STRUCTURE_URL: 'https://preprod-datatable.q9elements.com',
    DIAGRAM_URL: 'https://preprod-diagram.q9elements.com',
    DOMAIN: 'q9elements.com',
    ENTERPRISE_URL: 'https://preprod-corp.q9elements.com',
    ENV: 'preprod',
    GDPR_SUPPORT_LINK: 'https://support.elements.cloud/en/articles/1221094-enable-data-privacy-gdpr-compliance-for-a-ref-model',
    IMPORT_MAP_API: 'https://preprod-gateway.q9elements.com/map-import/api/v1',
    IMPORTS_API: 'https://preprod-gateway.q9elements.com/import/api/v1',
    INTERCOM_APP_ID: 'sfi4u1en',
    MAIN_APP_URL: 'https://preprod-app.q9elements.com',
    MAP_MANAGEMENT_APP: 'https://preprod-mapmgt.q9elements.com',
    NO_IMAGE_URL: '../../assets/images/no_image.png',
    PARTNER_APP: 'https://preprod-partnermgt.q9elements.com', 
    PREVIEW_HOLDER: '/assets/images/map_placeholder.png', 
    production: true, 
    RAVEN_DSN: 'https://eb6892047d9b42638a7563ddb26386ea@sentry.io/1233470', 
    RAVEN_ID: '9e76fe14aaba4a31846fe607572f9eb1',
    REF_MODEL_APP: 'https://preprod-refmodel.q9elements.com',
    RELEASE_URL: 'https://preprod-releasemgt.q9elements.com',
    REMOTE_UNLOCK_TTL: 3600000,
    REPORTS_API_ENDPOINT: 'https://preprod-gateway.q9elements.com/report/api/v1',
    REPORTS_APP: 'https://preprod-report.q9elements.com',
    SALESFORCE_CLIENT_ID: '3MVG9Rd3qC6oMalVszZtK2Usdv.BRF2ErH1PH2r2f2Aop_iJrJ0nCjq9blRIQKav.Aed1bBpVIVEZuMyw5VJC',
    SALESFORCE_REDIRECT_URL: 'https://preprod-app.q9elements.com/auth/callback',
    SALESFORCE_URL_TEST: 'https://test.salesforce.com/services/oauth2/authorize',
    SALESFORCE_URL: 'https://login.salesforce.com/services/oauth2/authorize',
    SECURE_COOKIES: true,
    SYS_ADMIN_APP: 'https://preprod-sysadmin.q9elements.com',
    TEAM_APP: 'https://preprod-spacemgt.q9elements.com',
    TOASTR_TIMEOUT: 5000,
    UNLOCK_TTL: 180000,
    fbConfig: {
        apiKey:'AIzaSyDLNqJjRPftfFZniGWiTAHzTfIN7YnZfJA',
        authDomain:'elements-preproduction.firebaseapp.com',
        databaseURL: 'https://elements-preproduction.firebaseio.com/',
        projectId: 'elements-preproduction',
        appId: '1:703846117176:web:a8806bbc49dbfd5cc19bcd'
    }
};
